// Generated by Framer (41c59c7)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["LmDGt3sdQ", "dVqU93KcV"];

const serializationHash = "framer-kmFne"

const variantClassNames = {dVqU93KcV: "framer-v-gd2myx", LmDGt3sdQ: "framer-v-7d5vya"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Close: "LmDGt3sdQ", Open: "dVqU93KcV"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, TdcKtjdDN: tap ?? props.TdcKtjdDN, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "LmDGt3sdQ"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, TdcKtjdDN, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "LmDGt3sdQ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapdlelqd = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (TdcKtjdDN) {
const res = await TdcKtjdDN(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "dVqU93KcV") return false
return true
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-7d5vya", className, classNames)} data-framer-name={"Close"} data-highlight layoutDependency={layoutDependency} layoutId={"LmDGt3sdQ"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTapdlelqd} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({dVqU93KcV: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1948mja"} data-framer-name={"Bottom"} layoutDependency={layoutDependency} layoutId={"Z5sI_1fHe"} style={{backgroundColor: "var(--token-485fa98d-eb94-46eb-a183-3a1ab798c476, rgb(9, 9, 9))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{dVqU93KcV: {rotate: -45}}}/>{isDisplayed() && (<motion.div className={"framer-1p7jxad"} data-framer-name={"Mid"} layoutDependency={layoutDependency} layoutId={"sNozjmw65"} style={{backgroundColor: "var(--token-485fa98d-eb94-46eb-a183-3a1ab798c476, rgb(9, 9, 9))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10}}/>)}<motion.div className={"framer-8dc1sx"} data-framer-name={"Top"} layoutDependency={layoutDependency} layoutId={"G0_DaEJwL"} style={{backgroundColor: "var(--token-485fa98d-eb94-46eb-a183-3a1ab798c476, rgb(9, 9, 9))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{dVqU93KcV: {rotate: 45}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-kmFne.framer-5qgw0k, .framer-kmFne .framer-5qgw0k { display: block; }", ".framer-kmFne.framer-7d5vya { cursor: pointer; height: 32px; overflow: hidden; position: relative; width: 32px; }", ".framer-kmFne .framer-1948mja { bottom: 7px; flex: none; height: 2px; left: calc(50.00000000000002% - 24px / 2); overflow: hidden; position: absolute; width: 24px; will-change: var(--framer-will-change-override, transform); }", ".framer-kmFne .framer-1p7jxad { flex: none; height: 2px; left: calc(50.00000000000002% - 24px / 2); overflow: hidden; position: absolute; top: calc(50.00000000000002% - 2px / 2); width: 24px; will-change: var(--framer-will-change-override, transform); }", ".framer-kmFne .framer-8dc1sx { flex: none; height: 2px; left: calc(50.00000000000002% - 24px / 2); overflow: hidden; position: absolute; top: 7px; width: 24px; will-change: var(--framer-will-change-override, transform); }", ".framer-kmFne.framer-v-gd2myx.framer-7d5vya { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 32px); }", ".framer-kmFne.framer-v-gd2myx .framer-1948mja { bottom: 15px; }", ".framer-kmFne.framer-v-gd2myx .framer-8dc1sx { top: 15px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"dVqU93KcV":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"TdcKtjdDN":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerBzjIRTL2H: React.ComponentType<Props> = withCSS(Component, css, "framer-kmFne") as typeof Component;
export default FramerBzjIRTL2H;

FramerBzjIRTL2H.displayName = "Icon / 32 / Menu-Close";

FramerBzjIRTL2H.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerBzjIRTL2H, {variant: {options: ["LmDGt3sdQ", "dVqU93KcV"], optionTitles: ["Close", "Open"], title: "Variant", type: ControlType.Enum}, TdcKtjdDN: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerBzjIRTL2H, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})